<template>
  <div class="base-station-table">
    <div style="display:flex;">
      <ul class="line-list">
         <div class="son-header  son-header-button" >
          <div>
             <span style="color: white"> 线路名称</span>
          </div>
        </div>
        <li v-for="(line, i) in allLinesOption" :key="i" :class="lineId == line.id ? 'line-actived' : ''" @click="chooseLine(line)" style="">{{ line.lineName }}</li>
      </ul>
      <el-table :data="stationTableData" border style="width: 100%" height="78vh">
        <el-table-column prop="stationName" label="基站名称" align="center" width="120px">
          <template slot-scope="scope">
            <span>{{ scope.row.stationName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onlineStatus" label="基站状态" align="center"></el-table-column>
        <el-table-column prop="towerIndex" label="塔杆号" align="center"></el-table-column>
        <el-table-column prop="towerName" label="塔杆名称" align="center"></el-table-column>
        <el-table-column prop="robotMac" label="设备MAC" align="center" width="150px"></el-table-column>
        <el-table-column prop="stationImei" label="设备序列号" align="center" width="180px"></el-table-column>
        <el-table-column prop="robotCharge" label="机器人充电开关" align="center" width="120px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.robotCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('robot', scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="batteryCharge" label="蓄电池充电开关" align="center" width="120px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.batteryCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('batter', scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="solarCharge" label="太阳能板电流开关" align="center" width="120px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.solarCharge" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" @change="Switch('solar', scope.row)"></el-switch>
   
          </template>
        </el-table-column>
        <el-table-column prop="chargeCurrent" label="充电电流" align="center" width="80px">
          <template slot-scope="scope">
            <span :class="scope.row.chargeCurrent > 15 ? 'redColor' : ''">{{ scope.row.chargeCurrent }} A</span>
          </template>
        </el-table-column>
        <el-table-column prop="batteryVoltage" label="蓄电池电压" align="center" width="90px">
          <template slot-scope="scope">
            <span :class="scope.row.batteryVoltage > 60 ? 'redColor' : ''">{{ scope.row.batteryVoltage }} V</span>
          </template>
        </el-table-column>
        <el-table-column prop="solarCurrent" label="太阳能板电流" align="center" width="90px">
          <template slot-scope="scope">
            <span :class="scope.row.solarCurrent > 15 ? 'redColor' : ''">{{ scope.row.solarCurrent }} A</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button title="编辑基站信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button title="删除基站" @click="deleteStation(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager-class">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination>
    </div>
    <!-- 添加基站 -->
    <el-dialog class="my-dialog" :title="stationDialogTitle" :close-on-click-modal="false" :visible.sync="baseStationDialogVisible" @closed="closedDialog('stationForm')">
      <el-form :model="stationForm" :rules="stationFormRules" ref="stationForm">
        <el-form-item label="基站名称" :label-width="formLabelWidth" prop="stationName">
          <el-input v-model.trim="stationForm.stationName" auto-complete="off" placeholder="请输入基站名称"></el-input>
        </el-form-item>
        <el-form-item label="所属线路" :label-width="formLabelWidth" prop="lineId">
          <el-select :disabled="!isAdd" v-model.trim="stationForm.lineId" placeholder="请选择基站所属线路" @change="getTowerByLine">
            <el-option v-for="(line, index) in allLinesOption" :key="index" :label="line.lineName" :value="line.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属杆塔" :label-width="formLabelWidth" prop="towerId">
          <el-select :disabled="!isAdd" v-model.trim="stationForm.towerId" placeholder="请选择所属杆塔">
            <el-option v-for="(item, index) in towerIndexOption" :key="index" :label="item.towerNumber" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备序列号" :label-width="formLabelWidth" prop="stationImei">
          <el-input v-model.trim="stationForm.stationImei" auto-complete="off" placeholder="请输入设备序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备MAC" :label-width="formLabelWidth" prop="robotMac">
          <el-input v-model.trim="stationForm.robotMac" auto-complete="off" placeholder="请输入设备地址"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" prop="serialNumber">
          <el-input v-model.trim="stationForm.serialNumber" auto-complete="off" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="baseStationDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";
import { getAllLineList } from "@/http/equipmentApi.js";
import { getselectTowers } from "@/http/homeApi.js";
import { getStations, addStation, deleteStation, updateStation, controlStation } from "@/http/baseStationApi.js";
import { mapState } from "vuex";
// import qs from "qs";
export default {
  name: "stationTable",
    components: {
    Icon
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      lineId: "",
      stationTableData: [],
      isAdd: true,
      stationDialogTitle: "添加基站",
      baseStationDialogVisible: false,
      formLabelWidth: "5vw",
      towerIndexOption: [],
      allLinesOption: [],
      stationForm: {
        batteryChargePeriod: 0,
        batteryCurrentPeriod: 0,
        id: 0,
        lineId: "",
        robotChargePeriod: 0,
        robotCurrentPeriod: 0,
        robotMac: "",
        solarChargePeriod: 0,
        solarCurrentPeriod: 0,
        stationImei: "",
        stationName: "",
        timeout: 0,
        towerId: ""
      },
      stationFormRules: {
        stationName: [{ required: true, message: "请输入基站名称", trigger: "blur" }],
        lineId: [{ required: true, message: "请选择所属线路", trigger: "blur" }],
        towerId: [{ required: true, message: "请选择所属所属杆塔", trigger: "blur" }],
        stationImei: [{ required: true, message: "请输入设备序列号", trigger: "blur" }],
        robotMac: [{ required: true, message: "请输入设备MAC", trigger: "blur" }]
      }
    };
  },
  computed: {
    ...mapState(["userMessage", "baseStationMessage"])
  },
  watch: {
    baseStationMessage: {
      handler(val) {
        console.log("监听到基站信息变化啦", val);
        // 改变表格中对应的数据
        for (let i = 0; i < this.stationTableData.length; i++) {
          const item = this.stationTableData[i];
               console.log("item",item);
          for (let j = 0; j < val.length; j++) {
            const newItem = val[j];
            if (item.robotMac == newItem.robotMac && item.towerIndex == newItem.num) {
              //mac相同并且所属塔号相同
              item.batteryVoltage = newItem.battery_voltage;
              item.chargeCurrent = newItem.charge_current;
              item.solarCurrent = newItem.solar_current;
              item.robotCharge = newItem.robot_charge;
              item.solarCharge = newItem.solar_charge;
              item.batteryCharge = newItem.battery_charge;
              console.log("数据",this.stationTableData);
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    Switch(type, row) {
      
      let params = {
        stationId: row.id,
        control: "",
        name: type,
        num: row.towerIndex,
        robotMac: row.robotMac,
        userName: this.userMessage.userName
      };
      switch (type) {
        case "batter":
          params.control = row.batteryCharge;
          break;
        case "solar":
          params.control = row.solarCharge;
          break;
        case "robot":
          params.control = row.robotCharge;
          break;
      }
      controlStation(params)
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.mesg);
            row.batteryCharge = "0";
            row.solarCharge = "0";
            row.robotCharge = "0";
          }
        })
        .catch(err => {
          console.log(err);
          if (err.status == 500) {
            this.$message.warning(err.data.data);
          }
        });
      console.log(params);
    },
    chooseLine(line) {
      this.lineId = line.id;
      this.getStationsTableData(this.lineId);
    },
    // 根据线路获取塔杆
    getTowerByLine(val) {
      let line = this.allLinesOption.find(item => {
        return item.id == val;
      });
      getselectTowers({ lineName: line.lineName }).then(res => {
        this.towerIndexOption = res.rows;
      });
    },
    // 打开对话框
    showDialog() {
      this.baseStationDialogVisible = true;
    },
    // 关闭对话框时回调
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.stationDialogTitle = "添加基站";
      this.stationForm = {
        towerId: "",
        lineId: "",
        robotMac: "",
        serialNumber: ""
      };
    },
    // 编辑按钮
    handleEdit(row) {
      console.log(row);
      this.stationForm = JSON.parse(JSON.stringify(row));
      this.stationForm.lineId = this.lineId;
      this.getTowerByLine(this.lineId);
      this.isAdd = false;
      this.stationDialogTitle = "修改基站信息";
      this.showDialog();
    },
    // 对话框保存按钮
    save() {
      if (this.isAdd) {
        this.addStationBtn();
      } else {
        this.updateStationBtn();
      }
    },
    // 添加基站
    addStationBtn() {
      let params = this.stationForm;
      this.$refs.stationForm.validate(valid => {
        if (valid) {
          addStation(params)
            .then(res => {
              if (res.meta.code == 200) {
                this.$message.success(res.meta.msg);
                this.baseStationDialogVisible = false;
                this.getStationsTableData(this.lineId);
              } else {
                this.$message.warning(res.meta.msg);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.warning(err.data.data);
              }
            });
        }
      });
    },
    // 更新基站
    updateStationBtn() {
      let params = this.stationForm;
      this.$refs.stationForm.validate(valid => {
        if (valid) {
          updateStation(params).then(res => {
            if (res.meta.code == 200) {
              this.$message.success(res.meta.msg);
              this.baseStationDialogVisible = false;
              this.getStationsTableData(this.lineId);
            } else {
              this.$message.error(res.meta.msg);
            }
          });
        }
      });
    },
    // 删除基站
    deleteStation(row) {
      this.$confirm("此操作将永久删除基站, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          console.log(res);
          deleteStation({ id: row.id })
            .then(res => {
              if (res.meta.code == 200) {
                this.getStationsTableData(this.lineId);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },
    // 获取基站表格数据
    getStationsTableData(lineId) {
      let params = { lineId: lineId };
      getStations(params).then(res => {
        res.data.stations.forEach(station => {
          //初始化开关和电流/电压
          station.robotCharge = "0";
          station.batteryCharge = "0";
          station.solarCharge = "0";
          station.chargeCurrent = "0.00";
          station.solarCurrent = "0.00";
          station.batteryVoltage = "0.00";
        });
        this.stationTableData = res.data.stations;
        // this.total = res.total;
      });
    },
    // 获取全部线路
    getAllLineList() {
      getAllLineList().then(res => {
        this.allLinesOption = res.rows;
        this.lineId = res.rows[0].id;
        this.getStationsTableData(res.rows[0].id);
      });
    },

    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // 调用分页查询接口
      this.getStationsTableData(this.lineId);
    }
    // // 模糊查询机器人
    // searchRobot(keyWord) {
    //   let params = {
    //     pageNumber: this.pageNumber,
    //     pageSize: this.pageSize,
    //     condition: keyWord
    //   };
    //   getRobotFuzzy(params).then(res => {
    //     this.stationTableData = res.rows;
    //     this.total = res.total;
    //   });
    // }
  },
  mounted() {
    this.getAllLineList();
  }
};
</script>
<style lang="scss" scoped>
.redColor {
  color: #ff0;
}
ul.line-list,
.line-list li {
  margin: 0;
  padding: 0;
}
ul.line-list {
  width: 180px;
  margin-right: 20px;
}
.line-list li {
  font-size: 12px;
  height: 4vh;
  line-height: 4vh;
  list-style: none;
  color: #fff;
  cursor: pointer;
  border-bottom:0.1vh solid #36e6bd60; ;
}
.line-list li:hover {
  color: #00ffbf;
}
li.line-actived {
  color: #028f6d;
}
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
