<template>
  <div class="reboot-table">
    <el-form :inline="true" :model="formInline" class="form-inline">
        <el-form-item label="机器人" class="form-item">
            <el-select v-model="formInline.robot" placeholder="选择机器人" size="small" value-key="name" :name="formInline.robot.name" @change="getRebootModelTableData">
                <el-option v-for="(item,index) in getAllOnlineRobotList" :key="index" :label="item.name" :value="item"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="模块名称" class="form-item">
            <div>{{formInline.robot.restartModuleName}}</div>
        </el-form-item>
        <el-form-item label="查询类型" class="form-item">
            <el-select v-model="formInline.selectType" placeholder="选择查询类型" size="small" @change="getRebootModelTableData">
                <el-option label="在线状态" :value="0"></el-option>
                <el-option label="操作记录" :value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="时间范围" class="form-item">
            <el-date-picker
              v-model="formInline.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button class="kr-normal-button" @click="getRebootModelTableData">查询</el-button>
        </el-form-item>
    </el-form>
    <div v-show="formInline.selectType==0" style="width: 100%;height:100%">
      <el-table :data="rebootModel.data" border style="width: 100%" height="65vh">
        <el-table-column type="index" label="序号" align="center" width="150"></el-table-column>
        <el-table-column prop="onlineStatus" label="状态记录" align="center"></el-table-column>
        <el-table-column prop="onlineTime" label="时间记录" align="center"></el-table-column>
      </el-table>
      <div class="pager-class">
        <el-pagination background layout="prev, pager, next" :total="rebootModel.total" :page-size="rebootModel.pageSize" :current-page.sync="rebootModel.pageNumber" @current-change="handlePageChange(rebootModel.pageNumber)"></el-pagination>
      </div>
    </div>
    <div v-show="formInline.selectType==1" style="width: 100%;height:100%">
      <el-table :data="operationModel.data" border style="width: 100%" height="65vh">
        <el-table-column type="index" label="序号" align="center" width="150"></el-table-column>
        <el-table-column prop="restartRecord" label="机器记录" align="center"></el-table-column>
        <el-table-column prop="userName" label="操作用户" align="center"></el-table-column>
        <el-table-column prop="dickMac" label="操作终端" align="center"></el-table-column>
        <el-table-column prop="internetMsg" label="网络信息" align="center"></el-table-column>
        <el-table-column prop="operateType" label="操作类型" align="center"></el-table-column>
        <el-table-column prop="operateTime" label="操作时间" align="center"></el-table-column>
      </el-table>
      <div class="pager-class">
        <el-pagination background layout="prev, pager, next" :total="operationModel.total" :page-size="operationModel.pageSize" :current-page.sync="operationModel.pageNumber" @current-change="handlePageChange(operationModel.pageNumber)"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import qs from "qs";
import { getRebootModel } from "@/http/rebootModelApi.js";
import { getAllRobot } from "@/http/professionalControlApi.js";
import { getOperation,getRecord } from "@/http/equipmentApi.js";
import Icon from "@/components/Icon.vue";
import { mapState,mapGetters } from "vuex";
export default {
  name: "rebootModelTableNew",
  components: {
    Icon
  },
  data() {
    return {
      rebootModel:{
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        data: []
      },
      operationModel:{
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        data: []
      },
      formInline:{
          robot:{},
          selectType:0,//0是在线状态，1是操作记录
          dateRange:[new Date(new Date().getTime()-30*24*3600*1000),new Date()]
      }
    };
  },
  computed: {
    ...mapState(["userMessage"]),
    ...mapGetters(["getAllOnlineRobotList"])
  },
  methods: {
    // 获取表格数据
    async getRebootModelTableData() {
      try{
        let params = {
          "robotId": this.formInline.robot.id,
          "moduleName": this.formInline.robot.restartModuleName,
          "isOperation": this.formInline.selectType == 1,
          "beginTime": this.dealDate(this.formInline.dateRange[0]),
          "endTime": this.dealDate(this.formInline.dateRange[1]),
          // "pageNumber": 1,
          // "pageSize": 10
        }; 
        if(this.formInline.selectType == 1){
          params.pageNumber = this.operationModel.pageNumber
          params.pageSize = this.operationModel.pageSize
          const res = await getOperation(params)
          this.operationModel.data = res.rows;
          this.operationModel.total = res.total;
        }else{
          params.pageNumber = this.rebootModel.pageNumber
          params.pageSize = this.rebootModel.pageSize
          const res = await getRecord(params)
          this.rebootModel.data = res.rows;
          this.rebootModel.total = res.total;
        }
      }catch(err){
        console.log("🚀 ~ file: rebootModelTableNew.vue ~ line 100 ~ getRebootModelTableData ~ err", err)
      }
    },
    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // 调用分页查询接口
      this.getRebootModelTableData();
    },
    // 获取该用户下的所有机器人,并保存在vuex中
    getAllRobotList() {
      let params = {
        pageNumber: 1,
        pageSize: 1000,
      };
      getAllRobot(params).then((res) => {
        this.$store.commit("saveAllRobot", res.rows);
        this.formInline.robot = this.getAllOnlineRobotList[0]||{}
        this.getRebootModelTableData();
      });
    },
    dealDate(date){
      date = date || new Date()
      const yearStr = date.getFullYear()
      const monthStr = date.getMonth()+1
      const dateStr = date.getDate()
      const hourStr = date.getHours()
      const minStr = date.getMinutes()
      const secStr = date.getSeconds()
      return `${yearStr}-${monthStr}-${dateStr} ${hourStr}:${minStr}:${secStr}`
    }
  },
  mounted() {
    if(this.getAllOnlineRobotList.length==0){
        this.getAllRobotList()
    }else{
        this.formInline.robot = this.getAllOnlineRobotList[0]
        this.getRebootModelTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.form-inline{
   color: #00ffbf;
   padding: 15px;
   .form-item{
     margin-right: 20px;
   }
}
.pager-class {
  display: flex;
  justify-content: center;
}
/deep/ .el-form-item__label{
       color: #fff !important;
   }
/deep/ .el-input__inner{
            border: 1px solid #00a880 !important;
            border-radius: 0;
            background-color: transparent;
            color:#fff !important;
        }
/deep/ .el-range-input{
          background-color: transparent;
          color:#fff !important;
        }
</style>
