<template>
  <div class="equipment-page">
    <RouterMenu />
    <div class="top-button">
      <div class="tab-btn">
        <el-button title="添加机器人" @click="type = 'robot'" :class="type == 'robot' ? 'choosed' : ''">
          机器人
          <i v-if="type == 'robot'" class="el-icon-circle-plus-outline" @click="add('robot')"></i>
        </el-button>
        <el-button title="添加摄像头" @click="type = 'camera'" :class="type == 'camera' ? 'choosed' : ''">
          摄像头
          <i v-if="type == 'camera'" class="el-icon-circle-plus-outline" @click="add('camera')"></i>
        </el-button>
        <el-button v-if="baseStationPermissionID.includes(bureauId)" title="添加太阳能基站" @click="type = 'baseStation'" :class="type == 'baseStation' ? 'choosed' : ''">
          基站
          <i v-if="type == 'baseStation'" class="el-icon-circle-plus-outline" @click="add('baseStation')"></i>
        </el-button>
        <el-button v-if="baseStationPermissionID.includes(bureauId)" @click="type = 'rebootModel'" :class="type == 'rebootModel' ? 'choosed' : ''">
          重启模块
          <i v-if="type == 'rebootModel'" class="el-icon-circle-plus-outline" @click="add('rebootModel')" title="添加重启模块"></i>
        </el-button>
        <el-button v-if="baseStationPermissionID.includes(bureauId)" @click="type = 'rebootModelRecord'" :class="type == 'rebootModelRecord' ? 'choosed' : ''">
          重启模块记录
        </el-button>
        <el-button v-if="baseStationPermissionID.includes(bureauId)" @click="type = 'warnRecord'" :class="type == 'warnRecord' ? 'choosed' : ''">
          告警记录
        </el-button>
        <el-button v-if="baseStationPermissionID.includes(bureauId)" @click="type = 'controlRecord'" :class="type == 'controlRecord' ? 'choosed' : ''">
          设备控制记录
        </el-button>
      </div>
      <div class="search-input" >
        <el-input v-model="searchKey" placeholder="搜索" clearable @clear="clearQuery" @keyup.enter.native="query"></el-input>
        <i class="el-icon-search" @click="query" title="点击搜索"></i>
      </div>
    </div>
    <div class="table-class">
      <!-- 机器人表格 -->
      <robotTable v-if="type == 'robot'" ref="robotTable" />
      <!-- 摄像头表格 -->
      <cameraTable v-if="type == 'camera'" ref="cameraTable" />
      <!-- 太阳能基站表格 -->
      <baseStationTable v-if="type == 'baseStation'" ref="baseStationTable" />
      <!-- 重启模块 -->
      <rebootModelTable v-if="type == 'rebootModel'" ref="rebootModelTable" />
      <!-- 重启模块记录 -->
      <rebootModelRecord v-if="type == 'rebootModelRecord'"/>
      <!-- 设备控制记录表格 -->
      <controlRecordTable v-if="type == 'controlRecord'" ref="controlRecordTable" />
      <!-- 告警记录表格 -->
      <warnRecordTable v-if="type == 'warnRecord'" ref="warnRecordTable" />
    </div>
  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import robotTable from "@/components/equipment/robotTable.vue";
import cameraTable from "@/components/equipment/cameraTable.vue";
import baseStationTable from "@/components/equipment/baseStationTable.vue";
import rebootModelTable from "@/components/equipment/rebootModelTable.vue";
import rebootModelRecord from "@/components/equipment/rebootModelTableNew.vue";
import controlRecordTable from "@/components/equipment/controlRecordTable.vue";
import warnRecordTable from "@/components/equipment/warnRecordTable.vue";
import { mapState } from "vuex";
export default {
  name: "equipment",
  components: {
    RouterMenu,
    robotTable,
    cameraTable,
    baseStationTable,
    rebootModelTable,
    controlRecordTable,
    warnRecordTable,
    rebootModelRecord
  },
  data() {
    return {
      type: "robot", //判断显示摄像头还是机器人
      searchKey: "", //搜索关键字
      baseStationPermissionID: window.baseStationPermissionID
    };
  },
  computed: {
    ...mapState(["bureauId"])
  },
  watch: {
    searchKey: function(newVal) {
      if (newVal == "") {
        this.query();
      }
    }
  },
  created() {
    this.$store.commit("saveBureauId", Number(sessionStorage.getItem("bureauId")));
  },
  methods: {
    add(type) {
      if (type == "robot") {
        this.$refs.robotTable.showDialog();
      } else if (type == "camera") {
        this.$refs.cameraTable.showDialog();
      } else if (type == "baseStation") {
        this.$refs.baseStationTable.showDialog();
      } else if (type == "rebootModel") {
        this.$refs.rebootModelTable.showDialog();
      } else if (type == "warnRecord") {
        this.$refs.warnRecordTable.showDialog();
      } else if (type == "controlRecord") {
        this.$refs.controlRecordTable.showDialog();
      }
    },
    // 模糊查询
    query() {
      console.log("模糊查询");
      if (this.type == "robot") {
        this.$refs.robotTable.searchRobot(this.searchKey);
      } else {
        this.$refs.cameraTable.searchCamera(this.searchKey);
      }
    },
    // 清除模糊查询
    clearQuery() {
      console.log("清除模糊查询");
      if (this.type == "robot") {
        this.$refs.robotTable.getRobotTableData();
      } else {
        this.$refs.cameraTable.getCameraTableData();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.equipment-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
  .top-button {
    margin-top: 2vh;
    height: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button {
      padding: 0;
      width: 5vw;
      height: 3.2vh;
      font-size: 0.7vw;
      margin-left: 0.8vw;
      border-color: transparent;
      .add-btn {
        width: 1vw;
        height: 1vw;
      }
    }
    .search-input {
      width: 13vw;
    }
  }
  .table-class {
    margin-top: 1vh;
    .el-table {
      .table-btn {
        padding: 0;
        .icon {
          width: 1vw;
          height: 1vw;
        }
      }
    }
  }
}
</style>
