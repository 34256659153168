<template>
  <div class="reboot-table">
    <el-table :data="rebootModelTableData" border style="width: 100%" height="78vh">
      <el-table-column prop="stationName" label="设备" align="center"></el-table-column>
      <el-table-column prop="stationImei" label="设备序列号" align="center"></el-table-column>
      <el-table-column prop="dataPointName" label="数据点名称" align="center"></el-table-column>
      <el-table-column prop="action" label="值" align="center"></el-table-column>
      <el-table-column prop="result" label="结果" align="center"></el-table-column>
      <el-table-column prop="created" label="日期" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.created, "YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination>
    </div>
  </div>
</template>
<script>
// import qs from "qs";
import { mapState } from "vuex";
import { getControlRecords } from "@/http/equipmentApi.js";
import moment from "moment";
export default {
  name: "controlRecordTable",
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      rebootModelTableData: []
    };
  },
  computed: {
    ...mapState(["userMessage"])
  },
  methods: {
    dateFormat(stomp, type) {
      return moment(stomp).format(type);
    },
    // 获取表格数据
    getControlRecordTableData() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getControlRecords(params).then(res => {
        this.rebootModelTableData = res.rows;
        this.total = res.total;
      });
    },
    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // 调用分页查询接口
      this.getControlRecordTableData();
    }
  },
  mounted() {
    this.getControlRecordTableData();
  }
};
</script>
<style lang="scss" scoped>
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
