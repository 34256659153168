<template>
  <div class="reboot-table">
    <el-table :data="rebootModelTableData" border style="width: 100%" height="78vh">
      <el-table-column prop="deviceName" label="设备名称" align="center">
        <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope.row.deviceName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="onlineStatus" label="设备状态" align="center"></el-table-column>
      <el-table-column prop="robotName" label="所属机器" align="center"></el-table-column>
      <el-table-column prop="deviceImei" label="设备序列号" align="center"></el-table-column>
      <el-table-column prop="reboot" label="机器人重启开关" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.reboot" active-color="#13ce66" inactive-color="#ccc" active-value="1" inactive-value="0" @change="Switch(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button title="编辑设备信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除设备" @click="deleteReboot(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination>
    </div>
    <el-dialog class="my-dialog" :title="robotDialogTitle" :close-on-click-modal="false" :visible.sync="robotDialogVisible" @closed="closedDialog('deviceForm')">
      <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm">
        <el-form-item label="设备名称" :label-width="formLabelWidth" prop="deviceName">
          <el-input v-model.trim="deviceForm.deviceName" auto-complete="off" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="所属机器" :label-width="formLabelWidth" prop="robotMac">
          <el-select v-model.trim="deviceForm.robotMac" placeholder="请选择机器人">
            <el-option v-for="(item, index) in robotOption" :key="index" :label="item.name" :value="item.robotMac"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备序列号" :label-width="formLabelWidth" prop="deviceImei">
          <el-input v-model.trim="deviceForm.deviceImei" auto-complete="off" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <!-- <el-form-item label="机器Mac" :label-width="formLabelWidth" prop="robotMac">
          <el-input v-model.trim="deviceForm.robotMac" auto-complete="off" placeholder="请输入机器人Mac地址"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="robotDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addRebootModel, deleteRebootModel, getRebootModel, updateRebootModel } from "@/http/rebootModelApi.js";
import { controlStation } from "@/http/baseStationApi.js";

import { getRobots } from "@/http/equipmentApi.js";
// import qs from "qs";
import Icon from "@/components/Icon.vue";
import { mapState } from "vuex";
export default {
  name: "rebootModelTable",
  components: {
    Icon
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      rebootModelTableData: [],
      isAdd: true,
      robotDialogTitle: "添加设备",
      robotDialogVisible: false,
      formLabelWidth: "5vw",
      robotOption: [],
      allLinesOption: [],
      deviceForm: {
        deviceImei: "",
        deviceName: "",
        robotMac: ""
      },
      deviceFormRules: {
        deviceName: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        deviceImei: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        robotMac: [
          { required: true, message: "请输入机器人Mac地址", trigger: "blur" },
          {
            required: true,
            min: 12,
            max: 12,
            message: "机器人Mac地址为12位",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapState(["userMessage"])
  },
  methods: {
    // 打开对话框
    showDialog() {
      this.robotDialogVisible = true;
    },
    // 关闭对话框时回调
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.robotDialogTitle = "添加设备";
      this.deviceForm = {
        deviceImei: "",
        deviceName: "",
        robotMac: ""
      };
    },
    //
    Switch(row) {
      let params = {
        stationId: row.id,
        control: row.reboot,
        name: "rebootModel",
        num: 0,
        robotMac: row.robotMac,
        userName: this.userMessage.userName
      };
      controlStation(params)
        .then(res => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.mesg);
            row.reboot = "0";
          }
        })
        .catch(err => {
          if (err.status == 500) {
            this.$message.warning(err.data.data);
          }
        });
    },
    // 编辑按钮
    handleEdit(row) {
      this.deviceForm = JSON.parse(JSON.stringify(row));
      this.isAdd = false;
      this.robotDialogTitle = "修改设备信息";
      this.showDialog();
    },
    // 对话框保存按钮
    save() {
      if (this.isAdd) {
        this.addReboot();
      } else {
        this.updateReboot();
      }
    },
    // 添加设备
    addReboot() {
      let params = this.deviceForm;
      this.$refs.deviceForm.validate(valid => {
        if (valid) {
          addRebootModel(params)
            .then(res => {
              if (res.meta.code == 200) {
                this.$message.success(res.meta.msg);
                this.robotDialogVisible = false;
                this.getRebootModelTableData();
              } else {
                this.$message.warning(res.meta.msg);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.warning(err.data.data);
              }
            });
        }
      });
    },
    // 更新设备
    updateReboot() {
      let params = this.deviceForm;
      this.$refs.deviceForm.validate(valid => {
        if (valid) {
          updateRebootModel(params).then(res => {
            if (res.meta.code == 200) {
              this.$message.success(res.meta.msg);
              this.robotDialogVisible = false;
              this.getRebootModelTableData();
            } else {
              this.$message.error(res.meta.msg);
            }
          });
        }
      });
    },
    // 删除设备
    deleteReboot(row) {
      this.$confirm("此操作将永久删除设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          console.log(res);
          deleteRebootModel({ id: row.id })
            .then(res => {
              if (res.meta.code == 200) {
                this.getRebootModelTableData();
                this.$message.success(res.meta.msg);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },
    // 获取表格数据
    getRebootModelTableData() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getRebootModel(params).then(res => {
        this.rebootModelTableData = res.rows;
        this.total = res.total;
      });
    },
    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // 调用分页查询接口
      this.getRebootModelTableData();
    }
    // // 模糊查询机器人
    // searchRobot(keyWord) {
    //   let params = {
    //     pageNumber: this.pageNumber,
    //     pageSize: this.pageSize,
    //     condition: keyWord
    //   };
    //   getRobotFuzzy(params).then(res => {
    //     this.rebootModelTableData = res.rows;
    //     this.total = res.total;
    //   });
    // }
  },
  mounted() {
    this.getRebootModelTableData();
    getRobots({ pageNumber: 1, pageSize: 500 }).then(res => {
      this.robotOption = res.rows;
    });
  }
};
</script>
<style lang="scss" scoped>
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
