<template>
  <div class="robot-table">
    <el-table :data="tableData" border style="width: 100%" height="78vh">
      <el-table-column prop="date" label="摄像头名称" align="center">
        <template slot-scope="scope">
          <Icon name="bottomArrow" />
          <span>{{ scope.row.cameraName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="摄像头IP" align="center"></el-table-column>
      <el-table-column prop="port" label="端口" align="center"></el-table-column>
      <el-table-column prop="robotName" label="所属机器人" align="center"></el-table-column>
      <el-table-column prop="rtmp" label="rtmp地址" align="center" width="450"></el-table-column>
      <el-table-column prop="user" label="用户名" align="center"></el-table-column>
      <el-table-column prop="password" label="密码" align="center"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button title="编辑摄像头信息" @click="handleEdit(scope.row)" type="text" class="table-btn"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
          <el-button title="删除摄像头" @click="deleteCamera(scope.row)" type="text" class="table-btn"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-class">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination>
    </div>
    <el-dialog class="my-dialog" :title="cameraDialogTitle" :close-on-click-modal="false" :visible.sync="cameraDialogVisible" @closed="clearValidate('cameraForm')">
      <el-form :model="cameraForm" :rules="cameraFormRules" ref="cameraForm">
        <el-form-item label="摄像头名称" :label-width="formLabelWidth" prop="cameraName">
          <el-input v-model.trim="cameraForm.cameraName" auto-complete="off" placeholder="请输入摄像头名称"></el-input>
        </el-form-item>
        <el-form-item label="所属机器人" :label-width="formLabelWidth" prop="robotId">
          <el-select v-model.trim="cameraForm.robotId" @change="selectRobot" placeholder="请选择所属机器人">
            <el-option v-for="(item, index) in robotListOption" :key="index" :label="item.robotName" :value="item.robotId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Ip地址" :label-width="formLabelWidth" prop="ip">
          <el-input v-model.trim="cameraForm.ip" auto-complete="off" placeholder="请输入摄像头IP"></el-input>
        </el-form-item>
        <el-form-item label="端口号" :label-width="formLabelWidth" prop="port">
          <el-input v-model.trim="cameraForm.port" auto-complete="off" placeholder="请输入摄像头端口"></el-input>
        </el-form-item>
        <el-form-item label="rtmp地址" :label-width="formLabelWidth" prop="rtmp">
          <el-input v-model.trim="cameraForm.rtmp" auto-complete="off" placeholder="请输入视频流地址"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model.trim="cameraForm.user" auto-complete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model.trim="cameraForm.password" auto-complete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="cameraDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getRobots, addCamera, updateCamera, delCamera, getCamera, getCameraFuzzy, h5sAddCamera, h5sDelCamera } from "@/http/equipmentApi.js";
import Icon from "@/components/Icon.vue";
export default {
  name: "cameraTable",

  components: {
    Icon
  },

  data() {
    return {
      cameraDialogTitle: "添加摄像头", //对话框标题
      cameraDialogVisible: false, //对话框是否显示
      formLabelWidth: "5vw", //label宽度
      robotListOption: [], //添加摄像头时下拉选择机器人
      isAdd: true, //是否添加
      cameraForm: {
        //添加表单
        ip: "",
        cameraName: "",
        password: "",
        port: "",
        robotId: "",
        robotName: "",
        rtmp: "",
        user: ""
      },
      cameraFormRules: {
        //表单验证
        cameraName: [{ required: true, message: "请输入机器人名称", trigger: "blur" }],
        robotId: [{ required: true, message: "请选择所属机器人", trigger: "blur" }],
        ip: [
          { required: true, message: "请输入机器人IP地址", trigger: "blur" },
          {
            required: true,
            message: "IP地址格式错误",
            pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            trigger: "blur"
          }
        ],
        port: [
          { required: true, message: "请输入机器人端口号", trigger: "blur" },
          { required: true, pattern: /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]{1}|6553[0-5])$/, message: "端口格式错误", trigger: "blur" }
        ],
        rtmp: [
          { required: true, message: "请输入摄像头RTMP地址", trigger: "blur" },
          // {required:true,message:"rtmp地址格式错误",validator:validateRtmp,trigger:"blur"}
          {
            required: true,
            message: "rtmp地址格式错误",
            pattern: /^rtmp:\/\/(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]):([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])\/live\/[A-Za-z0-9]{12}_live\d$/,
            trigger: "blur"
          }
        ]
      },
      tableData: [
        //表格数据
      ],
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      oldRTMP: ""
    };
  },

  methods: {
    // 显示对话框
    showDialog() {
      this.cameraDialogVisible = true;
    },
    // 关闭对话框时回调
    clearValidate(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.cameraDialogTitle = "添加摄像头";
      this.cameraForm = {
        //添加表单
        ip: "",
        cameraName: "",
        password: "",
        port: "",
        robotId: "",
        robotName: "",
        rtmp: "",
        user: ""
      };
    },
    // 编辑按钮
    handleEdit(row) {
      this.cameraForm = JSON.parse(JSON.stringify(row));
      this.isAdd = false;
      this.cameraDialogTitle = "修改摄像头信息";
      this.oldRTMP = row.rtmp;
      this.showDialog();
    },
    // 保存
    save() {
      if (this.isAdd) {
        this.addCamera();
      } else {
        this.updateCamera();
      }
    },
    // 添加摄像头
    addCamera() {
      let params = this.cameraForm;
      this.$refs.cameraForm.validate(valid => {
        if (valid) {
          addCamera(params).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.mesg);
              this.getCameraTableData();
              this.cameraDialogVisible = false;
              params.robotName = this.cameraForm.robotName;

              // 在第三方接口-在h5s中配置摄像头
              let arr = params.rtmp.split("/");
              let newtoken = arr[arr.length - 1];
              let data2 = {
                cameraName: this.cameraForm.robotName + "_" + newtoken,
                token: newtoken,
                user: params.user,
                password: params.password,
                audio: false,
                url: params.rtmp,
                session: "9e339280-f20e-483b-b572-babe9620174b"
              };
              h5sAddCamera(data2).then(res => {
                console.log(res);
              });
            } else {
              this.$message.error(res.meta.msg);
            }
          });
        }
      });
    },
    // 更新摄像头
    updateCamera() {
      let params = this.cameraForm;
      this.$refs.cameraForm.validate(valid => {
        if (valid) {
          updateCamera(params).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.mesg);
              this.getCameraTableData();
              this.cameraDialogVisible = false;

              // 在h5s中删除原来的配置，再添加新的配置
              let arr = this.oldRTMP.split("/");
              let oldtoken = arr[arr.length - 1];
              let data = {
                token: oldtoken,
                session: "9e339280-f20e-483b-b572-babe9620174b"
              };
              // 在第三方接口-删除配置
              h5sDelCamera(data).then(res => {
                console.log(res);
                let arr = params.rtmp.split("/");
                let newtoken = arr[arr.length - 1];
                let data2 = {
                  cameraName: this.cameraForm.robotName + "_" + newtoken,
                  token: newtoken,
                  user: params.user,
                  password: params.password,
                  audio: false,
                  url: params.rtmp,
                  session: "9e339280-f20e-483b-b572-babe9620174b"
                };
                // 在第三方接口-添加新配置
                h5sAddCamera(data2).then(res => {
                  console.log(res);
                });
              });
            } else {
              this.$message.error(res.meta.msg);
            }
          });
        }
      });
    },
    // 删除摄像头
    deleteCamera(row) {
      this.oldRTMP = row.rtmp;
      this.$confirm("此操作将永久删除摄像头, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          delCamera({ id: row.id })
            .then(res => {
              if (res.code == '000000') {
                this.cameraDialogVisible = false;
                this.$message.success(res.mesg);
                this.getCameraTableData();
                // 在第三方接口h5s中删除原来的配置
                let arr = this.oldRTMP.split("/");
                let oldtoken = arr[arr.length - 1];
                let data = {
                  token: oldtoken,
                  session: "9e339280-f20e-483b-b572-babe9620174b"
                };
                h5sDelCamera(data).then(res => {
                  console.log(res);
                });
              }
            })
            .catch(err => {
              this.$message.error(res.mesg);
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err, "取消操作");
        });
    },
    // 获取摄像头列表
    getCameraTableData() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getCamera(params).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 下拉选择机器人
    selectRobot() {
      this.robotListOption.forEach(item => {
        if (item.robotId === this.cameraForm.robotId) {
          this.cameraForm.robotName = item.robotName;
        }
      });
    },
    // 获取机器人-添加摄像头时下拉选择
    getRobotList() {
      let params = {
        pageNumber: 1,
        pageSize: 1000
      };
      getRobots(params).then(res => {
        this.robotListOption = res.rows;
      });
    },
    // 模糊查询摄像头
    searchCamera(keyWord) {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        condition: keyWord
      };
      getCameraFuzzy(params).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      console.log(pageNumber);
      // 调用分页查询接口
      this.searchCamera(this.$parent.searchKey)
    }
  },

  mounted() {
    this.getRobotList();
    this.getCameraTableData();
  }
};
</script>
<style lang="scss" scoped>
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
